import { FC } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Swiper } from 'swiper/react';
import { Pagination, Navigation, Autoplay, SwiperOptions } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const StyledSwiper = styled(Swiper)<{ navigation: any; pagination: any }>`
  width: 100%;
  ${props => props.pagination && `padding-bottom:${props.theme.spacing(6)} !important;`};
  ${props =>
    props.navigation != null && props.navigation === true
      ? css`
          .swiper-button-prev::after,
          .swiper-button-next::after {
            color: #666;
            font-size: 1rem;
            font-weight: bold;
          }
          .swiper-slide > div {
            margin: 0 20px;
          }
          ${props.theme.breakpoints.up('md')} {
            .swiper-slide > div {
              margin: 0 8px;
            }
          }
        `
      : ''}

  .swiper-pagination {
    bottom: 0;
    .swiper-pagination-bullet {
      padding: ${props => props.theme.spacing(0.8)};
      transition: background-color 0.2s ease-out;
    }

    .swiper-pagination-bullet-active {
      background-color: ${props => props.theme.palette.primary.main};
    }
  }
`;

export type SwiperProps = {
  delay?: number;
  loop?: boolean;
  slidesPerView?: number;
  hasNavigation?: boolean;
  hasPagination?: boolean;
  observer?: boolean;
  centeredSlides?: boolean;
  swiperProps?: Partial<SwiperOptions>;
  className?: string;
};

export const CustomSwiper: FC<SwiperProps> = ({
  delay,
  slidesPerView = 1,
  loop = true,
  hasNavigation = true,
  hasPagination = true,
  observer = true,
  centeredSlides,
  children,
  swiperProps,
  className,
}) => {
  return (
    <StyledSwiper
      modules={[Pagination, Navigation, Autoplay]}
      loop={loop}
      slidesPerView={slidesPerView}
      pagination={hasPagination ? { clickable: true } : undefined}
      navigation={hasNavigation}
      autoplay={delay != null ? { delay } : undefined}
      observer={observer}
      centeredSlides={centeredSlides}
      {...swiperProps}
      className={className}
    >
      {children}
    </StyledSwiper>
  );
};

export default CustomSwiper;
